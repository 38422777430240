import React from "react";
import Particles from "react-particles-js";

function Network() {
  return (
    <div className="App">
      <Particles
        params={{
          particles: {
            number: { value: 80, density: { enable: true, value_area: 800 } },
            color: { value: "#740ebb" },
            opacity: {
              value: 0.5,
              random: false,
              anim: { enable: false, speed: 1, opacity_min: 0.1, sync: false },
            },
            size: {
              value: 3,
              random: true,
              anim: { enable: false, speed: 40, size_min: 0.1, sync: false },
            },
            line_linked: {
              enable: true,
              distance: 252.52724532232722,
              color: "#8e32e1",
              opacity: 0.29987610382026364,
              width: 0,
            },
            move: {
              enable: true,
              speed: 1.5782952832645452,
              direction: "right",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: { enable: false, rotateX: 600, rotateY: 1200 },
            },
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: { enable: true, mode: "bubble" },
              onclick: { enable: false, mode: "push" },
              resize: true,
            },
            modes: {
              grab: {
                distance: 377.61590372012546,
                line_linked: { opacity: 0.2550139023708069 },
              },
              bubble: {
                distance: 239.76023976023976,
                size: 12.181158184520177,
                duration: 2,
                opacity: 1,
              },
              repulse: { distance: 200, duration: 0.4 },
              push: { particles_nb: 1 },
              remove: { particles_nb: 2 },
            },
          },
          retina_detect: true,
        }}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      />
    </div>
  );
}

export default Network;
