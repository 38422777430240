import React, { useState } from "react";
import Network from "./Network";
import logo from "../../assets/logo.png";
import logoCircle from "../../assets/logo-circle.png";
import { Button, Modal } from "react-bootstrap";

function Home() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const submitForm = async (e: any) => {
    e.preventDefault();

    try {
      const response = await fetch("/.netlify/functions/sendmail", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: email,
        }),
      });

      if (!response.ok) {
        //not 200 response
        return;
      }

      alert("Registro de interesse enviado com sucesso!");

      //all OK
    } catch (e) {
      //error
    }
  };

  return (
    <>
      <Network />

      <section className="main">
        <img src={logo} alt="" />
        <h1>
          Conecte o seu programa de fidelidade em nossa <br />
          camada para
          <span className="highlight">
            <b> receber e fidelizar</b> novos clientes.
          </span>
        </h1>
        <Button className="btn btn-dp" onClick={handleShow}>
          QUERO CONHECER
        </Button>
      </section>

      <Modal size="lg" show={show} onHide={handleClose} dialogClassName="modal-90w" centered>
        <Modal.Body style={{ backgroundColor: "#ffda00" }}>
          <div
            className="video"
            style={{
              position: "relative",
              paddingBottom: "56.25%" /* 16:9 */,
              paddingTop: 25,
              height: 0,
            }}
          >
            <iframe
              title="Duplus Vídeo"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              src={`https://www.youtube.com/embed/lfmu6Dy4Mfc?controls=0`}
              frameBorder="0"
            />
          </div>
        </Modal.Body>
      </Modal>

      <section className="hiw">
        <div className="container">
          <div className="text">
            <h2>Como funciona?</h2>
            <p>Simples.</p>

            <h3>
              Para cada R$ 1,00 gasto o <br /> cliente ganha 1 ponto na rede
              <br /> Duplus.
            </h3>

            <p>
              Integramos o seu programa de fidelidade
              <br /> unindo forças onde a Duplus funciona em <br />
              uma <span className="highlight">segunda camada de atração.</span>
            </p>
          </div>

          <img src={logoCircle} alt="" className="logo-circle" />
        </div>
      </section>

      <section className="descr">
        <div className="container">
          <p>
            A vantagem para o cliente é poder trocar <br /> os pontos por prêmios em{" "}
            <span>
              qualquer
              <br /> outro estabelecimento
            </span>{" "}
            parceiro.
          </p>
          <p>
            E para o seu negócio, a Duplus trará
            <br /> clientes novos{" "}
            <span>
              especialmente indicados
              <br /> pela nossa inteligência e análise de dados.
            </span>
          </p>
        </div>
      </section>

      <section className="contact">
        <div className="container">
          <div className="form-container">
            <h3>Gostou?</h3>
            <p>O lançamento será em breve.</p>
            <p>Cadastre-se e seja pioneiro no universo Duplus.</p>
            <form onSubmit={submitForm}>
              <input type="text" className="form-control mb-2" onChange={(e) => setName(e.target.value)} placeholder="Nome Completo" required />
              <input type="email" className="form-control mb-2" onChange={(e) => setEmail(e.target.value)} placeholder="Seu melhor e-mail" required />
              <button className="btn btn-dp btn-block">PARTICIPAR</button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
